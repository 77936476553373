import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/Clubs/LitreryClub/GuessTale/1.jpg';
// import p2 from 'assests/Photos/Clubs/LitreryClub/GuessTale/2.jpg';
// import p3 from 'assests/Photos/Clubs/LitreryClub/GuessTale/3.jpg';
// import p4 from 'assests/Photos/Clubs/LitreryClub/GuessTale/4.jpg';
// import p5 from 'assests/Photos/Clubs/LitreryClub/GuessTale/5.jpg';
// import p6 from 'assests/Photos/Clubs/LitreryClub/GuessTale/6.jpg';
// import p7 from 'assests/Photos/Clubs/LitreryClub/GuessTale/7.jpg';
// import p8 from 'assests/Photos/Clubs/LitreryClub/GuessTale/8.jpg';


import SideBar1 from 'views/LiteraryClub/SideBar1';




import { Typography } from '@mui/material';
import Container from 'components/Container';


const GuessTale = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/GuessTale/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/GuessTale/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/GuessTale/3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/GuessTale/4.webp`;
  const p5 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/GuessTale/5.webp`;
  const p6 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/GuessTale/6.webp`;
  const p7 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/GuessTale/7.webp`;
  const p8 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/GuessTale/8.webp`;


  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      {
        src: p7,
        source:p7,
        rows: 1,
        cols: 1,
      },
      {
        src: p8,
        source: p8,
        rows: 1,
        cols: 1,
      },
      
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Guess the Tale
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
         Department : English department Date: 25 November 2022
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The Literary Association under the aegis of the English department organised a fairy tale themed event on 25th November 2022, for the students of classes 9 to 12. The teams were required to present a play based on a fairy tale which was adapted to modern sensibilities and reflected modern issues. 
      <br/>
      Astraea house presented their play based on ‘Rapunzel’, Aether adapted the play ‘The Princess and the Frog’, the houses Aura and Athena presented ‘Cinderella’. While the first two were humorous enactments of eventfully scripted drama, the latter two were heart touching plays of modern-day problems with a brilliant hue of realism. With an ebullient audience of 11th graders who followed and responded to every dialogue, the event was thoroughly enjoyed. 
   <br/><br/>
  The event highlighted that literature of any kind is universal and the themes they express is relevant across ages. It also gave the students an opportunity to think out of the box, fuse it with modern issues and present creatively.
<br/><br/>
<br/></Typography>
      </Box>
      <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
‘The Drama is a Great Revealer of Life’
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>

<br/>
        </Typography>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SideBar1 />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default GuessTale;